import React from "react"
import Banner from "./Banner"
import Newsletter from "./Newsletter"
import Layout from "../../layout"
import Seo from "../../seo"

const BlogDetails = ({ pageContext }) => {
  return (
    <Layout>
      <Seo title={pageContext.blog.title} pageId={pageContext.blog.id} />
      <Banner data={pageContext} />
      <Newsletter data={pageContext} />
    </Layout>
  )
}
export default BlogDetails
